import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '../../components/SEO';
import Theme from '../../components/Theme';
import Header from '../../components/Archive/Header';
import ArticleSection from '../../components/ArticleSection';
import Newsletter from '../../components/Newsletter';
import Pagination from '../../components/Pagination';
import GeneralLayout from '../../layouts/index';

const PressTemplate = ({ transition, data, pageContext }) => {
  const { group, index, pageCount, pathPrefix } = pageContext;
  const { contentfulPressArchive, contentfulNewsletter, site } = data;

  return (
    <GeneralLayout>
        <Theme theme="taupe" transition={transition}>
          <Seo
            siteData={site}
            seoData={contentfulPressArchive.seo}
            defaultTitle="Emergence - Press"
            pathName="/press"
          />
          <Header
            title={contentfulPressArchive.title}
            featuredArticle={contentfulPressArchive.featured}
            press={true}
            theme="taupe"
          />
          {group && (
            <ArticleSection
              articles={group}
              largeArticle={contentfulPressArchive.featured ? true : false}
              press={true}
            />
          )}
          {pageCount > 1 && <Pagination index={index} pageCount={pageCount} pathPrefix={pathPrefix} />}
          <Newsletter content={contentfulNewsletter} />
        </Theme>
    </GeneralLayout>
  );
};

PressTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  transition: PropTypes.object,
};

export default PressTemplate;

export const pageQuery = graphql`
  query PressPageQuery {
    contentfulPressArchive {
      ...PressArchiveHeaderQuery
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;
